<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex xs12>
        <v-dialog v-model="dialogadd" max-width="600px">
          <v-card>
            <!-- <v-form v-model="addcat" ref="addcat"> -->
            <v-card-title>
              <span class="headline">Top Collection</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      dense
                      v-model="collectionid"
                      class="mt-5"
                      label="Sub Category"
                      :items="subcategoryArray"
                      item-text="name"
                      item-value="_id"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogadd = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="add(storage._id)">
                Save
              </v-btn>
            </v-card-actions>
            <!-- </v-form> -->
          </v-card>
        </v-dialog>
      </v-flex>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            <v-layout wrap>
              Products
              <v-spacer></v-spacer>
              <v-flex xs12 md3 pr-2>
                <v-text-field
                  v-model="keyword"
                  solo
                  dense
                  @keyup.enter.native="getSearch()"
                  label="Search"
                  single-line
                  hide-details
                >
                  <template v-slot:append>
                    <v-icon @click="getSearch()"> mdi-magnify </v-icon>
                  </template>
                </v-text-field>
              </v-flex>
              <!-- <v-flex xs12 md3>
                <v-text-field
                  v-model="search"
                  solo
                  dense
                  label="Page Search"
                  single-line
                  hide-details
                >
                  <template v-slot:append>
                    <v-icon> mdi-magnify </v-icon>
                  </template>
                </v-text-field>
              </v-flex> -->
            </v-layout>
            <v-layout wrap class="pt-10">
              <v-flex xs12 sm3>
                <v-autocomplete
                  v-model="filters.sellerid"
                  :items="sellerList"
                  item-text="organization"
                  item-value="_id"
                  outlined
                  clearable
                  dense
                  label="Seller"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm3 pl-3>
                <v-autocomplete
                  v-model="filters.omniId"
                  :items="omniList"
                  item-text="organization"
                  item-value="_id"
                  outlined
                  clearable
                  dense
                  label="Omni Store"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm3 pl-3>
                <v-select
                  ref="category"
                  v-model="filters.category"
                  :items="categoryArray"
                  item-text="name"
                  dense
                  outlined
                  clearable
                  item-value="_id"
                  color="#B1B1B1"
                  hide-details=""
                  placeholder="Category"
                  label="Category"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm3 pl-3>
                <v-select
                  ref="sub  category"
                  v-model="filters.subcategory"
                  :items="subCategoryArrayFilter"
                  item-text="name"
                  outlined
                  item-value="_id"
                  dense
                  color="#B1B1B1"
                  hide-details=""
                  clearable
                  placeholder="Sub Category"
                  label="Sub Category"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm3>
                <v-select
                  ref="styl"
                  v-model="filters.styl"
                  :items="stylCategoryArray"
                  item-text="name"
                  outlined
                  dense
                  item-value="_id"
                  color="#B1B1B1"
                  clearable
                  hide-details=""
                  placeholder="Style Category"
                  label="Style Category"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-layout wrap v-if="product.length > 0">
            <v-flex v-for="(item, i) in product" :key="i" md3 pa-4>
              <v-card tile flat style=""
                ><br />
                <v-layout wrap v-if="item.photos">
                  <v-flex xs12 align-self-center v-if="item.photos.length > 0">
                    <v-img
                      :src="mediaURL + item.photos[0]"
                      height="200px"
                      contain
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#FF2323"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                      <!-- <v-layout justify-start  v-if="item.productTag">
                    <v-flex xs8 align-self-start text-left>
                      <v-card
                        class="clip"
                        color="#FF0000"
                        height="10vh"
                        width="100%"
                      >
                        <v-layout justify-start style="height: 6vh">
                          <v-flex
                            xs4
                            align-self-center
                            text-center
                            style="transform: rotate(-40deg)"
                          >
                            <v-layout wrap justify-center>
                              <span
                                class="text-uppercase pr-1"
                                style="
                                  font-weight: bold;
                                  color: #fff;
                                  font-size: 9px;
                                "
                                >Best
                              </span>
                            </v-layout>
                            <v-layout wrap justify-center>
                              <span
                                class="text-uppercase pr-1"
                                style="
                                  font-weight: bold;
                                  color: #fff;
                                  font-size: 9px;
                                "
                                >Seller</span
                              >
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout> -->
                    </v-img>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center px-2>
                  <v-flex xl12 text-left style="line-height: 16px">
                    <span
                      @click="info(item._id)"
                      style="font-size: 13px; color: #000000; cursor: pointer"
                    >
                      {{ item.productname }}
                    </span>
                    <br /><br />
                    <span
                      style="font-size: 13px; color: #000000"
                      v-if="item.brand"
                      >{{ item.brand.name }}</span
                    >
                    <span style="font-size: 13px; color: #000000" v-else>{{
                      item.seller.organization
                    }}</span
                    ><br /><br />
                    <v-layout wrap justify-start pb-2>
                      <v-flex text-left xs12 md6 lg6 xl6>
                        <span
                          v-if="item.isintopcollection"
                          style="font-size: 13px; color: green"
                        >
                          Top Collection
                        </span>
                        <span v-else style="font-size: 13px; color: #000000">
                          <v-btn
                            x-small
                            outlined
                            color="red"
                            @click="(dialogadd = true), (storage = item)"
                            style="text-transform: none"
                          >
                            + Top Collection
                          </v-btn>
                        </span>
                      </v-flex>
                      <!-- <v-flex xs12 md6>
                        <span
                          v-if="item.isinhouseofbeauty"
                          style="font-size: 13px; color: green"
                        >
                          House of Beauty
                        </span>
                        <span v-else>
                          <v-dialog v-model="item.delete" max-width="600px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                x-small
                                outlined
                                v-on="on"
                                v-bind="attrs"
                                color="red"
                                style="text-transform: none"
                              >
                                + House of Beauty
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-title
                                >Are you sure you want to add this House of
                                Beauty?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="item.delete = false"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="addHouseofBeauty(item._id)"
                                  >OK</v-btn
                                >
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog> </span
                        ><br /><br />
                      </v-flex> -->
                    </v-layout>
                    <!-- <br /><br /> -->

                    <span style="font-size: 13px; color: #000000">
                      Rs. {{ item.price }}
                    </span>
                    <span
                      class="pl-12 text-decoration-line-through"
                      style="font-size: 13px; color: #ff0000"
                      v-if="item.offer > 0"
                    >
                      Rs. {{ item.orginalprice }}
                    </span>
                    <br />
                    <span>
                      <v-rating
                        v-model="item.rating"
                        color="#FFD341"
                        background-color="#e0e0e0"
                        empty-icon="$ratingFull"
                        half-increments
                        readonly
                        hover
                        small
                      ></v-rating>
                    </span>
                    <br />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12>
              <div
                class="pt-2"
                v-if="pages > 1 && product && product.length > 0"
              >
                <v-pagination
                  :length="pages"
                  :total-visible="7"
                  v-model="currentPage"
                  color="#FF0000"
                ></v-pagination>
              </div>
            </v-flex>
          </v-layout>
          <v-layout pt-8 wrap justify-center v-if="product.length == 0">
            <v-flex xs12>
              <span style="font-size: 25px; color: #000000">
                Oops! No Products Found
              </span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  // props: ["sellerId"],
  data() {
    return {
      showsnackbar: false,
      msg: null,
      ServerError: false,
      appLoading: false,
      dialogadd: false,
      dialog: false,
      collectionid: "",
      keyword: "",
      currentPage: 1,
      pages: 0,
      count: 12,
      totalData: 0,
      totalRows: 0,
      product: [],
      fullproduct: [],
      data: [],
      subcategoryArray: [],
      storage: {},
      prevRoute: null,
      sellerList: [],
      categoryArray: [],
      subCategoryArrayFilter: [],
      stylCategoryArray: [],
      omniList:[],
      menu1: false,
      menu2: false,
      date: new Date(),
      from: null,
      to: null,
      search: "",
      filters: {
        sellerid: null,
        category: null,
        subcategory: null,
        styl: null,
        omniId:null,
      },
      searchkey: null,
    };
  },
  //   beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.prevRoute = from
  //   })
  // },
  watch: {
    // resultQuery: {
    //   deep: true,
    //   handler: function (newVal) {
    //     console.log("Ptod=", this.product);
    //     console.log("newva=", newVal);
    //     this.product = [];
    //     this.product = newVal;
    //   },
    // },
    currentPage() {
      this.$store.commit("changeCurrentPage", this.currentPage);
      this.getData();
    },
    from() {
      this.getData();
    },
    to() {
      this.getData();
    },

    filters: {
      handler(val) {
        if (val.category || val.subcategory || val.styl || val.sellerid || val.omniId)
          if (this.currentPage > 1) this.currentPage = 1;
        this.$store.commit("productPageFilters", this.filters);
        console.log(this.$store.state.productPageFilters, "filt");
        this.filtt;
        this.getData();
      },
      deep: true,
    },
  },
  computed: {
    filtt() {
      console.log("ret", this.$store.state.productPageFilters);

      return this.$store.state.productPageFilters;
    },
    // resultQuery() {
    //   var searchresult = [];
    //   if (this.search) {
    //     searchresult = this.fullproduct.filter((item) => {
    //       return this.search
    //         .toLowerCase()
    //         .split(" ")
    //         .every((v) => item.productname.toLowerCase().includes(v));
    //     });
    //   } else {
    //     searchresult = this.fullproduct;
    //   }
    //   return searchresult;
    // },
  },
  mounted() {
    // console.log(this.prevRoute.path,"path")
    this.getSellerList();
    this.getSubcategory();
    this.getCategory();
    this.getStylCategory();
    this.getOmniList();

    if (localStorage.getItem("sCategory") != null)
      this.filters.category = localStorage.getItem("sCategory");
    else localStorage.removeItem("sCategory");
    if (localStorage.getItem("sSubCategory") != null)
      this.filters.subcategory = localStorage.getItem("sSubCategory");
    else localStorage.removeItem("sSubCategory");
    if (localStorage.getItem("sSeller") != null)
      this.filters.sellerid = localStorage.getItem("sSeller");
    else localStorage.removeItem("sSeller");
    if (localStorage.getItem("sOmni") != null)
      this.filters.omniId = localStorage.getItem("sOmni");
    else localStorage.removeItem("sOmni");
    if (localStorage.getItem("sStyl") != null)
      this.filters.styl = localStorage.getItem("sStyl");
    else localStorage.removeItem("sStyl");
    if (this.$store.state.currentPage) {
      this.currentPage = this.$store.state.currentPage;
      this.getData();
    } else this.getData();
  },
  methods: {
    getCategory() {
      // this.appLoading = true;
      axios({
        url: "/category/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.categoryArray = response.data.data;
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getStylCategory() {
      // this.appLoading = true;
      axios({
        url: "/styl/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          this.stylCategoryArray = response.data.data;
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getSearch() {
      axios({
        url: "/product/all",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          // sellerid: this.sellerId,
          keyword: this.keyword,
        },
      })
        .then((response) => {
          // this.$route.query.currentPage=null
          if (response.data.status) {
            this.product = response.data.data;
            this.fullproduct = JSON.parse(JSON.stringify(response.data.data));
            this.appLoading = false;
          } else {
            this.showsnackbar = false;
            this.product = [];
            this.fullproduct = [];
            this.msg = response.data.msg;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getSellerList() {
      // this.appLoading = true;
      axios({
        url: "/seller/dropdown",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          this.sellerList = response.data.data;
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     getOmniList() {
      // this.appLoading = true;
      axios({
        url: "/omnistore/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          this.omniList = response.data.data;
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add(pid) {
      var data = {};
      data["ishighlight"] = false;
      data["collectionid"] = this.collectionid;
      data["product"] = pid;

      axios({
        url: "/home/topcollection/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.dialogadd = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSubcategory() {
      axios({
        url: "/subcategory/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: 0,
          page: 0,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          var temp = [];
          temp = response.data.data;
          this.subCategoryArrayFilter = response.data.data;

          for (var i = 0; i < temp.length; i++) {
            if (
              // temp[i]._id == "5fbf36eba76eedbd31f5f24c" ||
              temp[i]._id == "60a4ba2631b3c87026f6123f" ||
              // temp[i]._id == "6096ad5531b3c87026f6119f" ||
              // temp[i]._id == "61b7285ded76da75cc2835e9" ||
              // temp[i]._id == "61b72897ed76da75cc2835ea" ||
              temp[i]._id == "64cb4b8b2e8917624db46606" ||
              temp[i]._id == "6555bd36617862ad338573b3"
            ) {
              this.subcategoryArray.push(temp[i]);
            }
          }
          // temp.reverse();
          // this.subcategoryArray = temp.slice(0, 3);
          this.sizeArray = response.data.data.size;
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/product/all",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          sort: this.sortType,
          sellerid: this.filters.sellerid,
          omniId: this.filters.omniId,
          category: this.filters.category,
          subcategory: this.filters.subcategory,
          styl: this.filters.styl,
          topcollection: true,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.product = response.data.data;
            this.pages = response.data.pages;
            this.data = response.data.data.category;
            this.totalData = response.data.count;
            this.fullproduct = JSON.parse(JSON.stringify(response.data.data));
            this.appLoading = false;
          } else {
            this.showsnackbar = false;
            this.product = [];
            this.fullproduct = [];
            this.msg = response.data.msg;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    info(pid) {
      this.$router.push("/productDetails/" + pid);
    },
    edit(pid) {
      this.$router.push({ name: "editProduct", params: { productid: pid } });
    },
  },
};
</script>
<style>
.clip {
  clip-path: polygon(50% 0%, 0% 0%, 0% 100%, 0% 100%);
}
</style>
